<template>

  <form
      @submit.prevent="signup(user)"
      style="width: 80%"
  >

    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <Logo width="250px"/>
      </v-col>
      <v-card-text v-if="error">
        <v-alert
            dense
            text
            type="error"
        >
          {{ error_text }}
        </v-alert>
      </v-card-text>
      <v-card-text v-if="success_register">
        <v-alert
            dense
            text
            type="success"
        >
          Registro Correcto
        </v-alert>
      </v-card-text>
      <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
        <p class="ml-2">Nombre</p>
        <v-text-field
            solo
            v-model="user.first_name"
            prepend-inner-icon="fas fa-user"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
        <p class="ml-2">Apellidos</p>
        <v-text-field
            solo
            v-model="user.last_name"
            prepend-inner-icon="fas fa-user"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
        <p class="ml-2">Email</p>
        <v-text-field
            solo
            v-model="user.email"
            prepend-inner-icon="fas fa-at"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
        <p class="ml-2">Telefono</p>
        <v-text-field
            solo
            v-model="user.phone"
            prepend-inner-icon="fas fa-phone"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex flex-column pa-0 " cols="12" sm="12">
        <p class="ml-2">Password</p>
        <v-text-field
            solo
            v-model="user.password"
            prepend-inner-icon="fas fa-lock"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>

      <v-col class="d-flex flex-column pa-0 " cols="12" sm="12">
        <p class="ml-2">Confirmar password</p>
        <v-text-field
            solo
            v-model="user.password_confirmation"
            prepend-inner-icon="fas fa-lock"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
        ></v-text-field>
        <p @click="change_form(true)" style="cursor: pointer; text-decoration: none" class="ml-2">¿Ya tienes una
          cuenta? Inicia sesión</p>
      </v-col>

      <v-btn
          class="pa-6"
          block
          style="color: white; margin-top: 30px"
          color="primary"
          type="submit"
      >
        Regístrate
      </v-btn>
    </v-row>

  </form>
</template>

<script>
import {mapActions} from "vuex";
import Logo from "../General/Logo";
export default {
  name: "Register",
  data() {
    return {

      show1: false,
      show2: false,
      success_register: false,
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        first_name: '',
        last_name: '',
        phone: ''
      },
      error: false,
      error_text: ''
    }
  },
  props: {
    change_form: Function,
    change_over: Function
  },
  components: {
    Logo,
  },
  methods: {
    ...mapActions("auth", ["registerUser", "setErrors",'registerUserBroker']),
    ...mapActions("subscription", ["createCustomerStripe"]),
    async signup(user) {
      this.error = false;
      await this.change_over(true);
      this.error_text = '';
      this.success_register = false;
      this.setErrors('clear');
      if (this.user.password !== this.user.password_confirmation){
        await this.change_over(false);
        this.sweetAlertProduct('Password no coinciden', 'white', 'error', 'red', 'white', 'top-end')
        return
      }
      const response = await this.registerUser(user);
      if (response.status === true) {
        const stripe = {
          email: user.email,
          phone: user.phone,
          name: user.first_name + ' ' + user.last_name,
        }
        const response_stripe = await this.createCustomerStripe(stripe)
        if (response_stripe.status === true){
          const broker = {
            stripe:response_stripe.id,
            user_id:response.id
          }
          const response_broker = await this.registerUserBroker(broker);
          if (response_broker === true){
            await this.change_over(false);
            this.sweetAlertProduct('<span>Registro Correcto Verifique su correo</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
            this.change_form(true)
          }else{
            await this.change_over(false);
            this.sweetAlertProduct(response_broker, 'white', 'error', 'red', 'white', 'top-end')
          }

        }else{
          await this.change_over(false);
          this.sweetAlertProduct(response_stripe, 'white', 'error', 'red', 'white', 'top-end')
        }

      } else {
        await this.change_over(false);
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }
    },
  }
}
</script>

<style scoped>

</style>
