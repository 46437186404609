<template>
  <v-app class="login" >
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card class="d-flex justify-center align-center flex-column">
        <v-card-title
            v-if="!messageConfirm"
            class="d-flex justify-center mb-5 mt-5"
        >
          <span class="headline">Recuperar password</span>
        </v-card-title>
        <v-card-text v-if="!messageConfirm">
          <v-alert
              v-for="(error, idx) in getErrors"
              :key="idx"
              dense
              text
              type="error"
          >
            {{ error }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Email"
                    v-model="emailRecovery"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-if="messageConfirm && !loader">
          <v-container
              class="d-flex flex-column justify-center align-center mt-5"
          >
            <v-icon color="primary" style="font-size: 40px">
              fas fa-check-circle
            </v-icon>
            <p class="mt-5" style="font-size: 20px" >
              Se te ha enviado un email para recuperar tu password
            </p>
          </v-container>
        </v-card-text>
        <v-card-text v-if="loader" class="d-flex justify-center mt-5">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog()">
            Cerrar
          </v-btn>
          <v-btn
              v-if="!messageConfirm"
              color="blue darken-1"
              text
              @click="sendEmailRecov()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="">

      <v-col
          cols="12"
          sm="6"
          md="6"
          lg="8"
          class="d-flex justify-center align-center loginImage"
      >

      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          class="d-flex justify-center align-center login-color"
      >
        <v-col cols="12" class="d-flex justify-center">
          <SignIn v-if="form_active" :Dialog="openDialog" :change_form="changeStatusForm"/>
          <Register :change_form="changeStatusForm" :change_over="changeOverlay" v-else/>
        </v-col>
      </v-col>
      <Overlay :overlay="overlay"/>
    </v-row>

  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SignIn from "../components/Auth/SignIn";
import Register from "../components/Auth/Register";
import Overlay from "../components/General/Overlay";
export default {
  name: "Login",
  data() {
    let user = {};
    return {
      form_active:true,
      overlay: false,
      user,
      show1: false,
      dialog: false,
      emailRecovery: "",
      messageConfirm: false,
      loader: false,
      panelSA: false,
      registerSucss: false,
      loader2: false,
    };
  },
  created() {
    this.setErrors("clear");

  },
  components:{
    SignIn,
    Register,
    Overlay
  },
  computed: {
    ...mapGetters("auth", ["getErrors"]),
  },
  methods: {
    ...mapActions("auth", [ "setErrors", "sendEmailRecoveryPass"]),
    async changeOverlay(state){
      this.overlay = state
    },
    async sendEmailRecov() {
      this.setErrors("clear");
      this.messageConfirm = true;
      this.loader = true;
      await this.sendEmailRecoveryPass({email: this.emailRecovery});
      if (this.getErrors.length > 0) {
        this.messageConfirm = false;
        this.loader = false;
      }
      this.loader = false;
    },
    openDialog() {
      this.setErrors("clear");
      this.dialog = true;
    },
    changeStatusForm(status){
      this.form_active = status
    },
    closeDialog() {
      this.messageConfirm = false;
      this.dialog = false;
      this.emailRecovery = "";
    },

  },


};
</script>

<style lang="scss" >
.loginImage {
  background-size: cover;
  background-image: url(../assets/images/hoyfacturo.jpeg);
  width: 500px;
}

.login-color {
  background: var(--secondary-color);
}
.v-icon {
  color: var(--v-primary-base) !important;
}
.theme--dark.v-input, .theme--dark.v-input input, .theme--dark.v-input textarea {
  color: black !important;
}
</style>
