<template>
  <form
      @submit.prevent="signin(user)"
      style="width: 80%"
  >
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <Logo width="250px"/>
      </v-col>

      <v-col
          v-if="!registerSucss && !loader2"
          cols="12"
          class="d-flex justify-center"
      >
        <p style="font-size: 24px">
          {{ panelSA ? "Crea tu cuenta" : "Inicia sesión en tu cuenta" }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-alert
          v-for="(error, idx) in getErrors"
          :key="idx"
          dense
          text
          type="error"
      >
        {{ error }}
      </v-alert>
    </v-row>

    <v-row v-if="!panelSA && !registerSucss">
      <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
        <p class="ml-2">Email</p>
        <v-text-field
            solo
            v-model="user.email"
            prepend-inner-icon="fas fa-at"
        ></v-text-field>
      </v-col>

      <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
        <p class="ml-2">Password</p>
        <v-text-field
            solo
            v-model="user.password"
            prepend-inner-icon="fas fa-lock"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
        ></v-text-field>
        <p
            @click.stop="Dialog"
            style="cursor: pointer"
            class="ml-2"
        >
          ¿Olvidaste tu password?
        </p>
      </v-col>

      <v-btn
          class="pa-6"
          block
          style="color: white"
          color="primary"
          type="submit"
      >
        Iniciar sesión
      </v-btn>
      <p @click="change_form(false)" style="cursor: pointer;" class="ml-2 mt-5">¿No tienes una cuenta? <span
          class="color-primary-h">Regístrate</span></p>
    </v-row>

    <v-row v-if="loader2">
      <v-col
          class="d-flex flex-column pa-0 align-center mt-5"
          cols="12"
          sm="12"
      >
        <v-progress-circular
            class="mt-5"
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Logo from "../General/Logo";

export default {
  name: "SignIn",
  data() {
    let user = {};
    return {
      show1: false,
      user,
      dialog: false,
      emailRecovery: "",
      messageConfirm: false,
      loader: false,
      panelSA: false,
      registerSucss: false,
      loader2: false,
    }
  },
  props: {
    Dialog: Function,
    change_form: Function
  },
  components: {
    Logo
  },
  created() {
    this.setErrors("clear");

  },
  computed: {
    ...mapGetters("auth", ["getErrors"]),
  },
  methods: {
    ...mapActions("auth", ["signin", "setErrors"]),

  },
}
</script>

<style scoped>

</style>
